export const SocialData = [
  {
    platform: "Instagram",
    link: "https://www.instagram.com/harieshwar_j_a/",
    icon: require("../../assets/icons/instagram.svg").default,
  },
  {
    platform: "Github",
    link: "https://github.com/HariEshwar-J-A",
    icon: require("../../assets/icons/github.svg").default,
  },
  {
    platform: "Facebook",
    link: "https://www.facebook.com/hari.eshwar.505",
    icon: require("../../assets/icons/facebook.svg").default,
  },
  {
    platform: "LinkedIn",
    link: "https://www.linkedin.com/in/harieshwar-jagan-abirami-325961171/",
    icon: require("../../assets/icons/linkedin.svg").default,
  },
  {
    platform: "Twitter",
    link: "https://twitter.com/hariSpartan_1",
    icon: require("../../assets/icons/twitter.svg").default,
  },
];
